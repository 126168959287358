import React from "react"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'

class WinesPage extends React.Component {
    render() {
        return (<StaticQuery
            query={graphql`
            query {
                image1: file(relativePath: {eq: "wines/buckets-of-pinot-noir.jpg"}) {
                  childImageSharp {
                    original {
                        src
                    }
                  }
                }
                image2: file(relativePath: {eq: "wines/noir_chardonnay_gris.jpg"}) {
                  childImageSharp {
                    original {
                        src
                    }
                  }
                }
              }  
        `}
            render={function (data) {
                return (<Layout>
                    <SEO title="Wines" />
                    <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
                        <header className="Header Header--bottom Header--overlay">
                            <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                                <div data-nc-container="bottom-left">
                                </div>
                                <div data-nc-container="bottom-center">
                                </div>
                                <div data-nc-container="bottom-right">
                                    <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                                        data-content-field="navigation">
                                    </nav>
                                </div>
                            </div>
                        </header>
                        <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
                            <section className="Intro Intro--has-image" data-parallax-original-element="" data-parallax-id="5afdfad0aa4a996443bea291"
                                data-controller="Parallax" data-controllers-bound="Parallax">
                                <div className="sqs-layout sqs-grid-12 columns-12 Intro-content sqs-alternate-block-style-container empty"
                                    data-layout-label="Intro" data-type="block-field" id="5afdfad0aa4a996443bea291_intro" style={{ marginTop: "0px" }}>
                                    <div className="row sqs-row">
                                        <div className="col sqs-col-12 span-12"></div>
                                    </div>
                                </div>
                                <figure className="Intro-image loaded" data-parallax-image-wrapper="" style={{ overflow: "hidden" }}>
                                    <img data-src={data.image1.childImageSharp.original.src}
                                        data-image={data.image1.childImageSharp.original.src}
                                        data-image-dimensions="2400x1600" data-image-focal-point="0.5,0.5" data-load="false" data-position-mode="standard"
                                        data-parent-ratio="4.3" alt="anderson-buckets-of-pinot-noir.jpg" className="" src={data.image1.childImageSharp.original.src}
                                        style={{ fontSize: "0px", left: "0px", top: "-414.333px", width: "1903px", height: "1268.67px", position: "relative" }}
                                        data-image-resolution="2500w" />
                                </figure>
                            </section>
                            <main className="Main Main--page" id="yui_3_17_2_1_1538622141779_162">
                                <section className="Main-content" data-content-field="main-content" id="yui_3_17_2_1_1538622141779_161">
                                    <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528825097543" id="page-5afdfad0aa4a996443bea291">
                                        <div className="row sqs-row" id="yui_3_17_2_1_1538622141779_160">
                                            <div className="col sqs-col-12 span-12" id="yui_3_17_2_1_1538622141779_159">
                                                <div className="row sqs-row" id="yui_3_17_2_1_1538622141779_158">
                                                    <div className="col-xs-12 col-md-7">
                                                        <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-f75e14c87400863bf898">
                                                            <div className="sqs-block-content">
                                                                <h1>Our wines</h1>
                                                                <p>“IMMACULATELY GROWN,” “TASTES ABSOLUTELY GORGEOUS,” “PIERCING PURITY,” “CLASSIC OREGON.”</p>
                                                                <h2>OUR PINOT NOIR</h2>
                                                                <p>Classic, rich, loaded with spice and earthy minerals. Plum, cinnamon, meat and cola-tinged
                                                                    briar wrapped in lush layers. </p>
                                                                <h3>Friends $42 | Retail $56</h3>
                                                                <h2>OUR DIJON CHARDONNAY</h2>
                                                                <p>Lengthy expressions of lemon-cream, pear, pie spices, minerals and herbs. Decidedly more
                                                                    Old World than L.A. </p>
                                                                <h3>Friends $36 | Retail $48</h3>
                                                                <h2>OUR PINOT GRIS</h2>
                                                                <p>Tiny amounts from time to time. Limited mostly to club members. Alsatian style: honeycomb and grapefruit flavors, tropical flower aromas. Bright with emollient viscosity. </p>
                                                                <h3>Friends $30 | Retail $40</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 order-first order-md-0 col-md-5" id="yui_3_17_2_1_1538622141779_157">
                                                        <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="14.537444933920705"
                                                            data-block-type="21" id="block-yui_3_17_2_1_1528824820566_12753">
                                                            <div className="sqs-block-content sqs-intrinsic" id="yui_3_17_2_1_1538622141779_180" style={{ paddingBottom: "14.5374%" }}>&nbsp;</div>
                                                        </div>
                                                        <div className="sqs-block image-block sqs-block-image sqs-text-ready" data-block-type="5" id="block-yui_3_17_2_1_1528824562686_3840">
                                                            <div className="sqs-block-content" id="yui_3_17_2_1_1538622141779_156">
                                                                <div className="image-block-outer-wrapper layout-caption-hidden design-layout-inline   " id="yui_3_17_2_1_1538622141779_155">
                                                                    <div className="intrinsic" style={{ maxWidth: "262.0px" }} id="yui_3_17_2_1_1538622141779_154">
                                                                        <div style={{ paddingBottom: "122.901%", overflow: "hidden" }} className="image-block-wrapper   has-aspect-ratio"
                                                                            data-description="" id="yui_3_17_2_1_1538622141779_153">
                                                                            <noscript><img src={data.image2.childImageSharp.original.src}
                                                                                alt="noir_chardonnay_gris.jpg" /></noscript>
                                                                            <img className="thumb-image loaded" data-src={data.image2.childImageSharp.original.src}
                                                                                data-image={data.image2.childImageSharp.original.src}
                                                                                data-image-dimensions="262x322" data-image-focal-point="0.5,0.5" data-load="false"
                                                                                data-image-id="5b200467562fa784f1c241db" data-type="image" data-position-mode="standard"
                                                                                alt="noir_chardonnay_gris.jpg" src={data.image2.childImageSharp.original.src}
                                                                                style={{ left: "0%", top: "0%", width: "100%", height: "100%", position: "absolute" }}
                                                                                data-image-resolution="300w" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </main>
                        </div>
                    </div>
                </Layout>)
            }
            } />)
    }
}

export default WinesPage
